    * { margin: 0;
        padding: 0;
        box-sizing: border-box;}

    a { text-decoration: none !important;}
    
    ul { margin: 0px !important;
        padding: 0px !important;
        list-style: none !important;}

    .container { max-width: 1240px !important;
                padding: 0px !important;}

    body { background-color: #140c2d !important;
            overflow-x: hidden !important;}

    .Toggel { width: 22px;}

    .b { color: #000;}

    .w {color: #fff !important;}

    .lc {color: #fdce39;}

    .josefin { font-family: "Josefin Sans", sans-serif;}

    .inter { font-family: "Inter", sans-serif;}

    .logo { width: 70px;}

    .nav_bar { justify-content: end !important;}

    .nav-item a {
        font-family: "Josefin Sans", sans-serif;
        color: #fff;
        
    }

    .nav-item {align-self: center;}

    .nav-link.active { color: #fff !important;}

    .dropdown_list {background: #000;}

    .drop_i_bg {
        background-color: #000 !important;
        font-size: 15px;
    }

    .dropdown_list li a{ color: #fff !important; 
                        padding: 10px 20px;}

    .baner_col1 {
        padding: 69px 10px;
    }

    nav.navbar.navbar-expand-lg {
        background: #000;
        padding: 10px 35px;
        border-radius: 37px;
        margin-top: 32px;
    }

    .sec01 { padding: 0px 20px 0px;
            position: relative;}

    .sub_title{
        font-size: 22px;
        background: linear-gradient(90.99deg, #fdce39 0.27%, #fd8963 30%, #ed5174 98.69%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .table_link  {
        font-size: 16px;
        background: linear-gradient(90.99deg, #fdce39 0.27%, #fd8963 30%, #ed5174 98.69%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
    }

    .for_img { width: 100%;}

    .banner_img_col { position: relative;}

    .banner_img {
        width: 100%;
        animation-name: run;
        position: absolute;
        animation-duration: 5s;
        height: 508px;
        /*object-fit: fill; */
        bottom: 0px;
    }

    @keyframes run {
                    0% {
                    left: 0;
                    }
                    50% { 
                    left: calc(100% - 100px); 
                    }
                    100% {
                    left: 0;     
                    }
                }

    .banner_title {
        font-size: 46px;
        font-weight: 700;
        margin: 20px 0px 25px;
    }

    .banner_text { font-size: 16px; 
                    color: #ddd6ed;}

    .align-self { align-self: center;}

    .btn_m {background: linear-gradient(90.99deg, #fdce39 0.27%, #fd8963 30%, #ed5174 98.69%);
            border-radius: 10px;
            display: inline-block;}

    .banner_btn {
                font-size: 24px;
                padding: 8px 40px;
                margin-top: 15px;
            }

    .sec02, .sec04, .sec01_pool1 { background-image: url(../Images/statistics-shape.png);
            background-position: left;
            background-repeat: no-repeat;
            background-size: cover;
            padding: 60px 20px;}

    .sec_heading_h2 {
        font-size: 36px;
        font-weight: 700;
    }

    .sec2_box {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 30px;
        padding: 20px ;
        /* max-width: 800px; */
        margin: 30px auto 0px;
    }

    .form_input.inter {
        width: 100%;
        background: rgba(20, 12, 45, 0.3);
        padding: 10px;
        border-radius: 10px;
        border: 1px solid #ffffff3b;
    }

    .form_label {
        margin-bottom: 10px;
        font-size: 17px;
        margin-top: 10px;
    }

    .sec2_box_text {
        font-size: 18px;
        background: linear-gradient(90.99deg, #fdce39 0.27%, #fd8963 30%, #ed5174 98.69%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .btn_m.form_btn{
        padding: 7px 20px;
        font-size: 16px;
        font-weight: 700;
        margin-top: 26px;
        /* margin-left: 31px; */
    }

    .sec03_con_w { padding: 60px 20px;}

    .sec03 { padding: 60px 20px;}

    .sec3_tabs {
        justify-content: space-around;
        border: none !important;
    }

    .tab_btn {
        font-size: 20px;
        padding: 12px 20px 8px;
        border-radius: 10px !important;
        background-color: #000 !important;
        border-image: linear-gradient(90.99deg, #fdce39 0.27%, #fd8963 30%, #ed5174 98.69%) !important;
    }

    .tab_btn:hover, .nav-link.tab_btn.active {background: linear-gradient(90.99deg, #fdce39 0.27%, #fd8963 30%, #ed5174 98.69%) !important;}

    /* .tab_box {
        background-color: #000;
        border-radius: 10px;
        padding: 20px;
        border-width: 0px 2px 0px 2px;
        border-style: solid;
        border-color: #FFD855;
    } */

    .tab_box{ background-image: url(../Images/statistics-shape.png);
        background-position: right;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;
        padding: 20px;
        border-width: 0px 2px 0px 2px;
        border-style: solid;
        border-color: #FFD855;}

    .tab_con { margin-top: 30px;}

    .tab_box_img {
        width: 72px;
    }

    .tab_box_title {
        font-size: 20px;
        margin-top: 25px;
    }

    .tab_box_text {
        font-size: 18px;
        background: linear-gradient(90.99deg, #fdce39 0.27%, #fd8963 30%, #ed5174 98.69%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 600;
    } 

    .nav-bar { gap: 17px;}

    .footer_list {
        justify-content: space-around;
    }

    footer {
        padding: 60px 20px 30px;
    }

    .footer_text {
        font-size: 14px;
        text-align: center;
        padding-top: 30px;
        margin-top: 30px;
        border-top: 1px solid #ffffff1c;
    }

    .form_join { display: flex;}

    .header { padding: 0px 20px; 
                margin-bottom: 20px;}

    .navbar-toggler-icon { background-image: none !important;}

    .form_img { height: 482px;}

    .sec02_ep { padding: 60px 20px;
                /* background-color: #000; */}

    table{ width: 100%;}

    .table_row { border-bottom: 2px solid #0000003b;}

    .table_data { font-size: 14px;
                padding: 10px 0px; }

    .table_heading { font-size: 16px;
                    padding-bottom: 10px;}

    /* .sec2_box { overflow-x: hidden;} */

    .over { overflow-x: scroll; }
.overi{
    overflow: hidden;
}
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
}

::-webkit-scrollbar-track-piece {
    background: #888;
}
::-webkit-scrollbar-thumb {
    background: #FBBD18;
}
    .tomax_tab {
        border-bottom: none !important;
        position: absolute;
        top: 14px;
        left: 36%;
    }

    /* ---------team tree css----------*/

    .mgt-container { flex-grow: 1;
        overflow: auto;
        justify-content: center;}

    .basic-style { background-color: #EFE6E2; }

    .mgt-item-parent p { font-weight: 400;
            color: #DE5454;}


    .management-tree .person { text-align: center;}

    .management-tree .person img { max-width: 80px; 
    border: 2px solid #d7d7d7;
    border-radius: 50%; 
    overflow: hidden;
    background-color: #f7f7f7;}

    .management-tree .person p.name {
        background-color: #f7f7f7;
        padding: 5px;
        font-size: 12px;
        font-weight: normal;
        color: #676767;
        margin: 0;
        position: relative;
        margin-top: 10px;
        border-radius: 10px;
        font-family: 'Inter';
    }

    .management-tree .person p.name:before { content: '';
    position: absolute; 
    width: 2px;
    height: 5px;
    background-color: #d7d7d7;
    left: 50%;
    top: 0; 
    transform: translateY(-100%);}

    .mgt-wrapper { display: flex;}

    .mgt-wrapper .mgt-item { display: flex;
    flex-direction: column; 
    margin: auto;}
    .mgt-wrapper .mgt-item .mgt-item-parent { margin-bottom: 50px;
    position: relative; 
    display: flex;
    justify-content: center;}

    .mgt-wrapper .mgt-item .mgt-item-parent:after { position: absolute;
    content: ''; 
    width: 2px;
    height: 25px;
    bottom: 0;
    left: 50%;
    background-color: #d7d7d7;
    transform: translateY(100%);}

    .mgt-wrapper .mgt-item .mgt-item-children { display: flex;
    justify-content: center;}

    .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child { padding: 0 15px; 
    position: relative;}

    .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:before, .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:after { content: ''; 
    position: absolute;
    background-color: #d7d7d7;
    left: 0;}

    .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:before { left: 50%;
    top: 0;
    transform: translateY(-100%);
    width: 2px; height: 25px;}

    .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:after { top: -23px;
    transform: translateY(-100%);
    height: 2px; width: 100%;}

    .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:first-child:after { left: 50%; 
    width: 50%;} 

    .mgt-wrapper .mgt-item .mgt-item-children .mgt-item-child:last-child:after { width: calc(50% + 1px);}

    /* ---------team tree css----------*/

    .withdraw_table { width: 1800px;}

    .pagi_nation { background-color: transparent !important;}

    .page-link:focus { box-shadow: none !important;}

    .table_pagi { justify-content: space-between;
        margin-bottom: 20px; }

    .table_sub_title  { font-size: 22px;}

    /* -----------Landing page--------- */

    .banner_sec { background-image: url(../Images/block-1.png);
                    background-position: center;
                    background-repeat: no-repeat;
                    position: relative;
                    background-size: cover;
                    padding: 80px 20px;
                    }

    .landing_sec01 {
        position: relative;
        padding: 0px 20px;}

    .banner_box { max-width: 900px;
                margin-left: auto;
                margin-right: auto;}

    .landing_banner_heading {
                    font-size: 43px;
                    font-weight: 700;
                    line-height: 55px;
                    margin-top: 20px;
                }

    .landing_sec02 { padding: 60px 20px;
                    background-color: #000;
                    position: relative;}

    .start_row { margin-top: 40px;}

    .s_box_title { font-size: 26px; }

    .s_box_text { font-size: 15px;}

    .landing_sec02_img {
        position: absolute;
        right: 0px;
        transform: rotate(180deg);
        height: 550px;}

    .landing_sec03 { padding: 60px 20px;}

    .par_box_img {
        width: 120px;
        border: 4px solid #ffd855;
        border-radius: 100px;
        margin-bottom: 25px;}

    .landing_sec03_img { width: 80%; }

    .con-wallet { width: 100%;}

    .sec01_con_w { padding: 60px 20px;}

    .sec02_con_w { padding: 60px 20px;
                    background-color: #000;}

    .text_max { max-width: 650px;
                margin: auto;}

    .partners-title { font-size: 20px;
                    margin-bottom: 25px;}

    .check_demo {
        padding: 9px 14px;
        font-size: 16px;
        font-weight: 700;}

    .table_user { width: 45px; }


    .sec01_login { padding: 0px 20px;}

    .header_btn {
        padding: 9px 15px 7px;
        font-size: 16px;
        font-weight: 500;
    }

    .head02 { justify-content: space-between;}

    .login_row { height: 100vh;
                align-items: center;}


    .tomax_btn:hover, .nav-link.tomax_btn.active {
        background: linear-gradient(90.99deg, #fdce39 0.27%, #fd8963 30%, #ed5174 98.69%) !important;
        padding: 8px 20px !important;
        border: none !important;
    }

    .tomax_btn { border-radius: 10px !important;
                padding: 6px 20px !important;
                border: 1px solid #fff !important;}

    @media (max-width:992px) {
        .banner_title { font-size: 36px;}
        
        .sec_heading_h2 {font-size: 30px;}

        .tab_btn { font-size: 16px;}

        .tab_box_title { font-size: 15px;}

        .tab_box_text { font-size: 16px;}

        .baner_col1 { padding: 36px 0px; }

        .landing_banner_heading {
            font-size: 35px;
            line-height: 44px;}

        .landing_sec03_img { width: 280px;
                margin-bottom: 40px; }
    }

    @media (max-width:767px) {

        table.con_table {
            width: 750px;
        }

        .landing_sec03_img { width: 80%; }

        .sec01_pool1 { padding: 30px 20px;}

        .table_link { font-size: 14px;}

        .table_heading { font-size: 14px;}

        .table_data { font-size: 14px;
                        padding: 5px 0px;}    

        .col-reverse{ flex-direction: column-reverse; }

        .banner_img { position: relative;
                    height: 100% !important;}

        .sec02 { padding: 0px 20px; }

        .sec04 { padding: 40px 20px; }

        .sec03 { padding: 40px 20px 0px; }

        .banner_title { font-size: 26px;
                        margin: 10px 0px 10px;}

        .banner_text { font-size: 14px;
                        color: #ddd6ed;}

        .banner_btn { font-size: 19px;
                        margin-top: 5px;}

        .sec2_box { margin: 17px auto 0px;}

        .sec_heading_h2 { font-size: 22px; }

        .form_label { font-size: 14px;}
        
        .tab_box { margin-bottom: 20px;}

        .logo { width: 45px; }

        .sub_title { font-size: 18px;}

        .form_join { display: block;}

        .tab_btn { font-size: 10px;}

        .tab_btn1 , .tab_btn2 { margin-bottom: 18px !important;}

        footer { padding: 20px;}

        .footer_text {
            font-size: 12px;
            padding-top: 20px;
            margin-top: 30px;
            border-top: 1px solid #ffffff1c;
        }

        .footer_list { flex-direction: column;}

        .footer_list_item { margin-top: 10px;}

        .footer_list_item1 { order: 2;}

        .footer_list_item2 { order: 3;}

        .footer_list_item3 { order: 1;}

        .footer_list_item4 { order: 4;}

        .footer_list_item5 { order: 5;}

        .btn_m.form_btn { font-size: 15px;
                            margin-top: 16px;}

        .col_p2 { margin-top: 20px;}

        .nav-bar { gap: 0px;}

        .MyTeamDirects_table { width: 915px;
                                margin-top: 40px; }
                                .MyTeamDirects_tables { width: 1147px;
                                    margin-top: 40px; }

        a.nav-link.josefin.w { font-size: 13px;}

        nav.navbar.navbar-expand-lg {padding: 10px 15px;
                                    margin-top: 15px;}

        .landing_banner_heading {
            font-size: 25px;
            line-height: 38px;}

        .s_box_title { font-size: 19px;}

        .s_box_text { font-size: 13px;}

        .landing_sec02 { padding: 40px 20px;}

        .landing_sec03 { padding: 40px 20px;}

        .landing_sec03_img { margin-bottom: 30px;}

        .sec02_ep { padding: 40px 20px;}
        
        .header { padding: 0px 10px;}

        .tomax_btn {
            font-size: 12px;
        }

        .tomax_tab {left: 15px;
                    top: 60px;}

        .table_sub_title { font-size: 15px;}

        .tomax_btn { padding: 5px 5px !important ;}

        .tomax_btn:hover, .nav-link.tomax_btn.active {padding: 8px 10px !important;}

        .api { margin-left: 10px !important;}
        
    }



    .page-link[disabled] {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
    }

    .ant-pagination-prev,
    .ant-pagination-next {
    background-color: white; 
    border-color: #d9d9d9; 
    color: #1890ff; 
    }

    .ant-pagination-prev:hover,
    .ant-pagination-next:hover {
    background-color: #f5f5f5; 
    }

    .ai{
        width: 100%;

    }
    .over1{
        margin: 10px!important;
        padding-bottom: 10px!important;
        overflow-x: scroll;
    }
    .io2{
        margin-top: 20px!important;
    }
    .back {
        width: 31px;
        padding-top: 13rem;
    }
    .r{
        text-align: center;
        margin-left: 29%!important;
    }
    .ios{
        border: none !important;
        margin-top: 30px !important;
    }
    .api{
        margin-left: 20px !important;
    }
    .io{
        margin-left: 43px !important;
    }
  
.sec_h2{
    font-size: 24px;
    font-weight: 700;
}
  
.box-h{
    height: 261px;
    
}

.boxsixe{
    display: flex;
    justify-content: space-between;
}
.boxsixes{
    display: flex;
    justify-content: space-evenly
}
    .sec_tom { position: relative;}
    .boxi{
        margin-top: 125px  !important;
    }
    .loader-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%; /* Full height of the parent */
    }
    .i{
        margin-top: 54px !important;
    }
    .iy{
        margin-top: 126px!important;
    }
    .pos{
        margin-top: 9px !important;
    }
    .pool1_table{
        width: 1200px !important;
    }

    @media (min-width:320px) and (max-width:767px) {
        .pos{
            margin-top: 19px !important;
        }
        .pool1_table{
            width: 800px !important;
        }
    }



    /* .ant-pagination-item-2, .ant-pagination-item-3 { display: none !important;} */
    .flex2 {
        display: flex;
        margin-top: 24px;
    }
    